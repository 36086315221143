.footer-container {
    position: absolute;
    width: 100%;
    background: #111;
    color: #555;
}

.footer-container * {
    display: inline-block;
}

.footer-container p {
    font-family: "Roboto", sans-serif;
    padding: 6px 0px;
}
