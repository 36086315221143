.info-container {
    width: 95%;
    margin: 40px auto 80px;
    max-width: 600px;
    background: #111;
}

.info-left, .info-right {
    transition: 200ms;
    margin: 60px auto 100px;
}

.info-left p,
.info-right p {
    font-size: 19px;
    padding: 0px 10px 20px;
    line-height: 28px;
    text-align: left;
    margin: 0px;
    color: #fff;
}

.info-left h2,
.info-right h2 {
    width: 90%;
    max-width: 350px;
    margin: 30px auto;
    font-size: 24px;
    font-weight: bold;
    transform: scale(1.1,1.0);
}

.info-left .info-image {
    width: 220px;
}

.info-right .info-image {
    width: 220px;
}

@media (min-width: 600px) {
    .info-container {
        width: 90%;
    }

    .info-left, .info-right {
        transition: 200ms;
    }

    .info-left p,
    .info-right p {
        font-size: 20px;
        padding: 10px 0px;
        line-height: 28px;
        text-align: left;
    }

    .info-left .info-image {
        width: 240px;
    }
    
    .info-right .info-image {
        width: 240px;
    }


}

@media (min-width: 1200px) {
    .info-left p,
    .info-right p {
        font-size: 22px;
        line-height: 32px;
    }

    .info-container {
        max-width: 1000px;
    }

    .info-left .info-image,
    .info-right .info-image {
        padding-bottom: 20px;
    }

    .info-left .info-image,
    .info-right .info-image {
        width: 100%;
    }

    .info-left .info-image-container {
        width: 30%;
        float: left;
    }

    .info-left .info-text-container {
        width: 65%;
        float: right;
    }

    .info-right .info-image-container {
        width: 30%;
        float: right;
    }

    .info-right .info-text-container {
        width: 65%;
        float: left;
    }

    .info-left, .info-right {
        margin: 120px auto;
    }

    .group-inv {
        height: 700px;
    }
    
    .strat-inv {
        height: 500px;
    }
    
    .clear-com {
        height: 500px;
    }
    
    .access-net {
        height: 500px;
    }
}

@media (min-width: 1600px) {
    .info-container {
        max-width: 1200px;
    }

    .info-left .info-image-container {
        width: 360px;
        float: left;
    }

    .info-right .info-image-container {
        width: 360px;
        float: right;
    }

    .info-left .info-text-container {
        width: 720px;
        float: right;
    }

    .info-right .info-text-container {
        width: 720px;
        float: left;
    }
}