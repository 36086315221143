.up-arrow {
    position: relative;
    top: 3px;
    width: 66px;
    height: 66px;
    display: inline-block;
}

.up-arrow svg {
    height: 60px;
    width: 60px;
}

.in-logo {
    position: relative;
    top: 3px;
    width: 40px;
    height: 40px;
    display: inline-block;
}

.in-logo svg {
    height: 30px;
    width: 30px;
}
