.desktop-container {
    display: none;
    background: fixed;
    background-size: cover;
    background-image: url("../images/lakeside.png");
    background-repeat: no-repeat;
    padding-bottom: 200px;
}

.d-about-container h1, .d-about-container h2 {
    display: inline;
    color: #fff;
    text-shadow: 0px 2px 4px black;
}

span {
    transform: scale(1.2,1.0);
    display: inline-block;
}

.d-about-container h1 {
    font-size: 32px;
}

.d-about-container h2 {
    font-size: 22px;
}

.d-about-container p {
    text-align: left;
    font-size: 18px;
    margin: 40px 0px 20px;
    line-height: 32px;
    color: #fff;
    text-shadow: 0px 2px 4px black;
}

@media (min-width: 600px) {
    .desktop-container {
        display: none;
    }

    .d-about-container {
        max-width: 500px;
    }
    
    .d-about-container h1, .d-about-container h2 {
        display: inline;
    }
    
    span {
        transform: scale(1.5,1.2);
        display: inline-block;
    }
    
    .d-about-container h1 {
        font-size: 32px;
    }
    
    .d-about-container h2 {
        font-size: 22px;
    }
    
    .d-about-container p {
        text-align: left;
        font-size: 21px;
        margin: 40px 0px 20px;
        line-height: 32px;
    }
}

@media (min-width: 1200px) {
    .desktop-container {
        display: block;
    }

    .d-about-container {
        max-width: 600px;
        display: inline-block;
        margin-right: 50px;
    }
}

@media (min-width: 1400px) {
    .d-about-container {
        margin-right: 100px;
    }
}

@media (min-width: 1600px) {
    .d-about-container {
        margin-right: 140px;
    }
}




.d-form-input {
    display: block;
    width: 90%;
    color: #333;
    font-size: 22px;
    padding: 8px 12px;
    margin: 20px auto;
    border: none;
    border-radius: 6px;
    background: white;
    box-shadow: 0px 2px 4px black;
}

.d-form-text p {
    text-align: center;
    font-size: 18px;
    line-height: 32px;
    color: #fff;
    text-shadow: 0px 2px 4px black;
}

.d-submit {
    display: block;
    transition: 200ms;
    width: 95%;
    padding: 12px;
    font-size: 22px;
    background: #fff;
    color: white;
    border-radius: 6px;
    border: none;
    margin: 40px auto 0px;
    box-shadow: 0px 2px 4px #888;
}

.d-submit:hover {
    transition: 200ms;
    cursor: pointer;
}

.d-about-button {
    transition: 200ms;
    width: 95%;
    padding: 12px;
    font-size: 22px;
    background: #43009e;
    color: white;
    border-radius: 6px;
    border: none;
    margin: 20px 0px 60px;
    box-shadow: 0px 2px 4px #888;
}

.d-about-button:hover {
    transition: 200ms;
    cursor: pointer;
}

.disabled {
    background: rgba(0, 0, 0, 0.2);
    color: #888;
    box-shadow: 0px 0px 0px 1px #888;
}

.disabled:hover {
    cursor: default;
    opacity: 1;
}

.d-submit-ok {
    background: white;
    color: green;
    box-shadow: 0px 0px 0px 1px green;
    opacity: 0.5;
}

.d-submit-ok:hover {
    opacity: 0.5;
    cursor: default;
}

.in-progress {
    background: white;
    color: goldenrod;
    box-shadow: 0px 0px 0px 1px goldenrod;
    opacity: 1;
}

.in-progress:hover {
    opacity: 1;
    cursor: default;
}

.input-disabled {
    opacity: 0.5;
}


@media (min-width: 600px) {
    .d-form-container {
        margin-top: 60px;
    }
    
    .d-form-text p {
        text-align: center;
        font-size: 20px;
        text-shadow: 0px 2px 4px black;
        line-height: 30px;
    }
    
    .d-submit {
        max-width: 600px;
        display: block;
        transition: 200ms;
        padding: 12px;
        font-size: 22px;
        background: rgba(0, 0, 0, 0.4);
        color: white;
        border-radius: 6px;
        margin-top: 10px;
        box-shadow: 0px 0px 0px 2px white;
    }
    
    .d-submit:hover {
        transition: 200ms;
        cursor: pointer;
        background: rgba(0, 0, 0);
    }
    
    .d-about-button {
        max-width: 600px;
        transition: 200ms;
        padding: 12px;
        font-size: 22px;
        background: #43009e;
        color: white;
        border-radius: 6px;
        border: none;
        margin: 20px 0px 60px;
        box-shadow: 0px 2px 4px rgb(0, 0, 0);
    }
    
    .d-about-button:hover {
        transition: 200ms;
        background: #5d00df;
        cursor: pointer;
    }
    
    .disabled {
        background: blue;
        color: #888;
        box-shadow: 0px 0px 0px 1px #888;
    }
    
    .disabled:hover {
        cursor: default;
        opacity: 1;
    }
    
    .d-submit-ok {
        color: white;
        background: green;
        box-shadow: 0px 0px 0px 1px green;
    }
    
    .d-submit-ok:hover {
        opacity: 0.5;
        cursor: default;
        color: white;
        background: green;
    }
    
    .in-progress {
        color: white;
        background: goldenrod;
        box-shadow: 0px 0px 0px 1px goldenrod;
        opacity: 1;
    }
    
    .in-progress:hover {
        opacity: 1;
        cursor: default;
        color: white;
        background: goldenrod;
    }
}

@media (min-width: 1000px) {
    .d-form-container {
        display: inline-block;
        width: 520px;
        position: relative;
        top: -60px;
    }
}