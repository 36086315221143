.nav-logo-container {
    position: fixed;
    z-index: 2000;
    width: 100%;
    background: #1d1d1d;
    padding: 0px;
    max-height: 97px;
}

.scrolled {
    transition: 300ms;
    top: 0px;
}

.hidden {
    transition: 300ms;
    top: -200px;
}

.nav-logo-image {
    margin: auto;
    max-width: 600px;
}

.nav-logo-cryo {
    width: 250px;
    position: relative;
    top: 20px;
}

.nav-logo-under {
    width: 100%;
    max-width: 400px;
    position: relative;
    top: 19px;
}
