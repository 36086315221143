.form-container {
    width: 100%;
    margin: auto;
    background: fixed;
    background-size: 100%;
    background-image: url("../images/lakeside-mobile.png");
    background-repeat: no-repeat;
}

.input {
    max-width: 600px;
    width: 85%;
    display: block;
    color: #333;
    font-size: 22px;
    padding: 8px 12px;
    margin: 20px auto;
    border: none;
    border-radius: 6px;
    background: white;
    box-shadow: 0px 2px 4px black;
}

.form-text p {
    width: 90%;
    padding-top: 0px;
    text-align: left;
    margin: 0px auto;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
}

.submit {
    max-width: 620px;
    width: 90%;
    display: block;
    transition: 200ms;
    padding: 8px;
    font-size: 22px;
    background: rgba(0, 0, 0, 0.2);
    color: white;
    border-radius: 6px;
    margin: 10px auto;
    box-shadow: 0px 0px 0px 2px white;
}

.submit:hover {
    transition: 200ms;
    cursor: pointer;
    opacity: 0.8;
}

.about-button {
    max-width: 620px;
    transition: 200ms;
    width: 90%;
    padding: 12px;
    font-size: 22px;
    background: #43009e;
    color: white;
    border-radius: 6px;
    border: none;
    margin: 10px 0px 60px;
    box-shadow: 0px 2px 4px #000;
}

.about-button:hover {
    transition: 200ms;
    cursor: pointer;
    background: #5d00df;
}

.disabled {
    background: rgba(0, 0, 0, 0.4);
    color: white;
}

.disabled:hover {
    cursor: default;
    opacity: 1;
}

.submit-ok {
    background: white;
    color: green;
    box-shadow: 0px 0px 0px 1px green;
    opacity: 0.5;
}

.submit-ok:hover {
    opacity: 0.5;
    cursor: default;
}

.in-progress {
    background: white;
    color: goldenrod;
    box-shadow: 0px 0px 0px 1px goldenrod;
    opacity: 1;
}

.in-progress:hover {
    opacity: 1;
    cursor: default;
}

.input-disabled {
    opacity: 0.5;
}


@media (min-width: 600px) {
    .form-text p {
        text-align: left;
        font-size: 21px;
        line-height: 30px;
    }

    .form-container {
        background: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../images/lakeside.png");
    }
}

@media (min-width: 1200px) {
    .form-container {
        display: none;
    }
}