.buttons-container {
    right: 30px;
    z-index: 1000;
}

.back-to-top {
    position: relative;
    top: 16px;
    transition: 500ms ease-out;
    opacity: 1;
    background: #2e126b;
    box-shadow: 1px 1px 4px #111111b6;
    color: white;
    border: none;
    padding: 0px;
    border-radius: 100px;
}

.linked-in {
    position: relative;
    top: 0px;
    transition: 500ms ease-out;
    opacity: 1;
    background: #2e126b;
    box-shadow: 1px 1px 4px #111111b6;
    color: white;
    border: none;
    padding: 13px;
    border-radius: 100px;
}

.back-to-top:hover {
    transition: 200ms;
    cursor: pointer;
    background: #5926c5;
}

.linked-in:hover {
    transition: 200ms;
    cursor: pointer;
    background: #5926c5;
}

.hidden {
    top: 120px;
    transition: 500ms ease-out;
    opacity: 0;
}

.hidden:hover {
    cursor: default;
    opacity: 0;
}

.buttons-container button {
    position: relative;
    display: inline;
    margin-left: 8px;
}

@media (min-width: 600px) {
    .back-to-top {
        position: relative;
        transition: 500ms ease-out;
        opacity: 1;
        color: white;
        border: none;
        box-shadow: 0px 2px 4px #111111b6;
        padding: 0px;
        border-radius: 100px;
    }
    
    .back-to-top:hover {
        cursor: pointer;
    }
    
    .hidden {
        transition: 500ms ease-out;
        opacity: 0;
    }
    
    .hidden:hover {
        cursor: default;
        opacity: 0;
    }
}