.logo-container {
    padding-bottom: 40px;
    top: 0;
    z-index: 1000;
    background: fixed;
    background-size: 100%;
    background-image: url("../images/lakeside-mobile.png");
    background-repeat: no-repeat;
}

/* .scrolled .logo img {
    transition: 200ms;
    width: 180px;
    padding: 10px 0px;
} */

.logo img {
    transition: 200ms;
    width: 200px;
}

.logo {
    max-width: 600px;
    padding-top: 40px;
    margin: auto;
    background: fixed;
    background-size: 100%;
    background-image: url("../images/lakeside-mobile.png");
    background-repeat: no-repeat;
}


@media (min-width: 400px) {
    .scrolled .logo img {
        transition: 200ms;
        width: 220px;
    }
    
    .logo img {
        transition: 200ms;
        width: 60%;
        max-width: 500px;
    }
    
    .logo-container {
        width: 100%;
        top: 0;
        z-index: 1000;
    }
}

@media (min-width: 600px) {
    .logo {
        background: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../images/lakeside.png");
    }

    .logo-container {
        background: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../images/lakeside.png");
    }
}

@media (min-width: 1200px) {
    .logo-container {
        padding-bottom: 160px;
        background: fixed;
        background-size: cover;
        background-image: url("../images/lakeside.png");
        background-repeat: no-repeat;
    }
}
