.about-container {
    width: 100%;
    margin: auto;
    background: fixed;
    background-size: 100%;
    background-image: url("../images/lakeside-mobile.png");
    background-repeat: no-repeat;
}

.about-container h1, .about-container h2 {
    display: inline;
    color: #fff;
}

span {
    transform: scale(1.2,1.0);
    display: inline-block;
}

.about-container h1 {
    font-size: 28px;
}

.about-container h2 {
    font-size: 20px;
}

.about-container p {
    text-align: left;
    width: 90%;
    max-width: 620px;
    font-size: 16px;
    margin: auto;
    padding: 20px 0px 20px;
    line-height: 28px;
    color: #fff;
}

@media (min-width: 600px) {
    .about-container {
        padding: 80px auto 10px;
        margin: auto;
        background: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../images/lakeside.png");
    }
    
    .about-container h1, .about-container h2 {
        display: inline;
    }
    
    span {
        transform: scale(1.5,1.2);
        display: inline-block;
    }
    
    .about-container h1 {
        font-size: 32px;
    }
    
    .about-container h2 {
        font-size: 22px;
    }
    
    .about-container p {
        text-align: left;
        font-size: 21px;
        margin: 40px auto 0px;
        line-height: 32px;
    }
}

@media (min-width: 1200px) {
    .about-container {
        display: none;
    }
}

@media (min-width: 1200px) {
    .about-container {
        max-width: 760px;
        margin: 230px auto 10px;
    }
}
